@import "varibles";
@import url('https://fonts.googleapis.com/css?family=Fauna+One');

@font-face {
  font-family: "Roboto";
  src: url('/fonts/Roboto.ttf');
  src: url('/fonts/Roboto?#iefix') format('embedded-opentype'),
  url('/fonts/Roboto.woff') format('woff'),
  url('/fonts/Roboto.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SignPainter";
  src: url('/fonts/SignPainter.ttf');
  src: url('/fonts/Roboto?#iefix') format('embedded-opentype'),
  url('/fonts/SignPainter.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "arial-bold";
  src: url('/fonts/Arial-Bold.ttf');
  src: url('/fonts/Arial-Bold?#iefix') format('embedded-opentype'),
  url('/fonts/Arial-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "copperplate";
  src: url('/fonts/Copperplate.ttf');
  src: url('/fonts/Copperplate?#iefix') format('embedded-opentype'),
  url('/fonts/Copperplate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "futura";
  src: url('/fonts/Futura-Medium.ttf');
  src: url('/fonts/Futura-Medium?#iefix') format('embedded-opentype'),
  url('/fonts/Futura-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "times-new-roman";
  src: url('/fonts/Times-New-Roman.ttf');
  src: url('/fonts/Times-New-Roman?#iefix') format('embedded-opentype'),
  url('/fonts/Times-New-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Brush";
  src: url('/fonts/BrushScriptStd.ttf');
  src: url('/fonts/BrushScriptStd?#iefix') format('embedded-opentype'),
  url('/fonts/BrushScriptStd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  min-height: 100%;
}

body {
  @include vw(
          $font-size: 18,
          $padding: 130 0 0
  );
  font-family: $ff;
  color: #333;
  line-height: 1.4;
  min-height: 100%;
  position: relative;
  background-color: #fff;
  overflow-x: hidden;
  @include respond-to(small){
    padding-top: 76px;
  }
}

h2 {
  color: $blue;
  text-align: center;
  display: inline-block;
  @include vw(
          $font-size: 40,
          $margin: 0 0 40,
          $padding: 0 10 15,
          $border-bottom: 1 solid $lightblue
  );
  @include respond-to(small){
    font-size: 25px;
    margin: 0 0 20px;
  }
}

p {
  @include vw(
          $font-size: 18,
          $margin-bottom: 20
  );
  line-height: 1.4;
}

a {
  color: $dark;
  transition: all 0.3s ease-in;
  text-decoration: none;
  &:hover, &:active, &:focus {
    transition: all 0.3s ease-in;
  }
}

img {
  max-width: 100%;
}

sup {
  font-size: 60%;
}

.g-site-width {
  @include vw($max-width: 1400);
  @include vw($padding: 0 15);
  width: 100%;
  margin: 0 auto;
  @include respond-to(small) {
    padding: 0 15px;
  }
;
}

/******* HEADER *******/
.g-header {
  background-color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  @include vw(
          $border-bottom: 2 solid $grey
  );
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include vw(
            $padding: 7 0
    );
    @include respond-to(small) {
      padding: 10px 0;
    }
    &__logo {
      img {
        width: 100%;
        display: flex;
        @include vw(
                $max-width: 250
        );
        @include respond-to(small) {
          max-width: 125px;
        }
      }
    }
    nav {
      display: flex;
      justify-content: space-between;
      a {
        color: $grey;
        font-weight: 600;
        font-family: $ff;
        text-transform: uppercase;
        display: inline-block;
        line-height: 1;
        @include vw(
                $font-size: 18,
                $padding: 0 24,
                $border-right: 2 solid $grey
        );
        &:hover,
        &:active,
        &:focus {
          color: $blue;
        }
        @include respond-to(small) {
          border-right: none;
          width: 100%;
          font-size: 18px;
          padding: 10px 0;
          text-decoration: none;
          &:hover{
            background: $grey;
            color: #fff;
          }
        }
        &:last-child {
          border-right: none;
          padding-right: 0;
        }
      }
      @include respond-to(small) {
        order: 8;
        flex-basis: 100%;
        flex-direction: column;
        align-items: center;
        display: none;

        &.open {
          display: flex;
          animation: click-expand .3s linear 0s;
          text-align: center;
        }
      }
    }
    &__icon {
      display: none;
      z-index: 99999999;
      position: absolute;
      right: 16px;
      top: 18px;
      @include respond-to(small) {
        display: block;
        width: 30px;
        height: 25px;
        margin: 10px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        float: right;
      }
      @include respond-to(xs) {
        margin: 10px 0;
      }
      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 50%;
        background: $dark;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        &:nth-child(even) {
          left: 50%;
        }
        &:nth-child(odd) {
          left: 0;
        }
        &:nth-child(1),
        &:nth-child(2) {
          top: 0;
        }
        &:nth-child(3),
        &:nth-child(4) {
          top: 11px;
        }
        &:nth-child(5),
        &:nth-child(6) {
          top: 22px;
        }
      }
      &.open {
        span {
          &:nth-child(1),
          &:nth-child(6) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:nth-child(2),
          &:nth-child(5) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
          &:nth-child(1) {
            left: 5px;
            top: 9px;
          }
          &:nth-child(2) {
            left: calc(50% - 5px);
            top: 9px;
          }
          &:nth-child(3) {
            left: -50%;
            opacity: 0;
          }
          &:nth-child(4) {
            left: 100%;
            opacity: 0;
          }
          &:nth-child(5) {
            left: 5px;
            top: 14px;
          }
          &:nth-child(6) {
            left: calc(50% - 5px);
            top: 14px;
          }
        }
      }
    }
  }

}

/******* FOOTER *******/

.g-footer {
  background: #00bcda;
  @include vw(
          $padding: 30 0
  );
  &__nav {
    @include vw(
            $padding: 0 0 30,
            $border-bottom: 2 solid #fff
    );
    nav {
      display: flex;
      justify-content: flex-end;
      @include respond-to(small){
        justify-content: center;
      }
      @include respond-to(470){
        flex-direction: column;
        align-items: center;
      }
    }
    a {
      @include vw(
              $font-size: 20,
              $padding: 0 24,
              $border-right: 2 solid #fff
      );
      text-transform: uppercase;
      display: inline-block;
      color: #fff;
      font-weight: 600;
      line-height: 1;
      &:hover,
      &:active,
      &:focus {
        color: $grey;
        text-decoration: underline;
      }
      @include respond-to(470){
        border-right: none;
        padding: 7px 20px;
      }
      &:first-child{
        padding-left: 0;
        @include respond-to(470){
          padding: 7px 20px;
        }
      }
      &:last-child {
        border-right: none;
        padding-right: 0;
        @include respond-to(470){
          padding: 7px 20px;
        }
      }
    }
  }
  &__contact {
    display: flex;
    align-items: center;
    justify-content: center;
    @include vw(
            $padding: 40 0
    );
    @include respond-to(small){
      flex-wrap: wrap;
    }
    &__item {
      width: 33%;
      @include vw(
              $padding: 0 5
      );
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include respond-to(small){
        width: 100%;
        padding: 0;
        margin-bottom: 30px;
        text-align: center;
        align-items: center;
        &:last-child{
          margin-bottom: 0;
        }
      }
      iframe {
        width: 100%;
        @include vw(
                $max-width: 500,
                $height: 300
        );
        @include respond-to(small){

        }
      }
      h3 {
        font-weight: bold;
        @include vw(
                $font-size: 28,
                $margin: 0 0 35
        );
        @include respond-to(small){
          font-size: 18px;
          margin-bottom: 20px;
        }
      }
      p {
        font-weight: bold;
        @include vw(
                $font-size: 20
        );
        a {
          font-weight: bold;
          color: #fff;
        }
      }
      &:nth-child(2) {
        p {
          @include vw(
                  $max-width: 240,
                  $margin: 0 auto 10
          );
          width: 100%;
          @include respond-to(small){
            max-width: 100%;
          }
          a {
            &:hover,
            &:active,
            &:focus {
              text-decoration: underline;
            }
          }
        }
      }
      &:first-child {
        a {
          display: inline-block;
          background: #f5f5f5;
          line-height: 1;
          @include vw(
                  $padding: 15 60,
                  $font-size: 30,
                  $border: 1 solid #fff,
                  $border-radius: 6
          );
          color: $blue;
          &:hover,
          &:active,
          &:focus {
            background: $blue;
            color: #fff;
          }
        }
      }
    }
  }
  &__copyright {
    text-align: center;
    color: #fff;
    position: relative;
    @include vw(
            $font-size: 20
    );
    p {
      position: absolute;
      right: 0;
      top: 0;
      color: $grey;
      margin: 0;
      @include respond-to(small){
        position: relative;
        margin-top: 10px;
      }
      a {
        color: $grey;
        &:hover,
        &:active,
        &:focus {
          text-decoration: underline;
        }
      }
    }

  }
}
