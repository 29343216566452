

/****  preloader   ******/
@-webkit-keyframes animsition-loading{
  0%{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)
  }
  to{
    -webkit-transform:rotate(1turn);
    transform:rotate(1turn)
  }
}
@keyframes animsition-loading{
  0%{
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg)
  }
  to{
    -webkit-transform:rotate(1turn);
    transform:rotate(1turn)
  }
}
.l-preload{
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}
.l-load{
  background-color: transparent;
  border-top: 5px solid rgba(0,0,0,.2);
  border-right: 5px solid rgba(0,0,0,.2);
  border-bottom: 5px solid rgba(0,0,0,.2);
  border-left: 5px solid #eee;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-name: animsition-loading;
  animation-name: animsition-loading;
  width: 32px;
  height: 32px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  border-radius: 50%;
  z-index: 2;
  &:after{
    width: 32px;
    height: 32px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
    border-radius: 50%;
    z-index: 2;
  }
}

//mobile menu
@keyframes click-expand {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
//////////////////////////////////////

.l-center{
  text-align: center;
}

.l-uppercase{
  text-transform: uppercase;
}
