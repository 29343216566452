.b-our-product {
  &__fiber {
    @include vw(
            $font-size: 22,
            $margin: 0 0 50
    );
    text-align: center;
  }
  &__styles {
    @include vw(
            $margin: 0 0 140,
            $padding: 60 0
    );
    background: linear-gradient(to right, #fdfdfd 29%, #e3f0f3 90%);
    @include respond-to(small) {
      margin-bottom: 90px;
    }
    &_features {
      background: linear-gradient(to left, #fdfdfd 29%, #e3f0f3 90%);
      @include vw(
              $margin: 0 0 70
      );
    }
    h3 {
      color: $blue;
      text-align: center;
      @include vw(
              $font-size: 28,
              $margin: 0 0 30
      );
    }
    ul {
      width: 100%;
      @include vw(
              $max-width: 400
      );
      margin: 0 auto;
      &.b-features {
        @include vw(
                $max-width: 780
        );
      }
      li {
        display: flex;
        @include vw(
                $font-size: 20,
                $margin: 0 0 20
        );
        &:last-child {
          margin-bottom: 0;
        }
        &:before {
          content: '\2714';
          @include vw(
                  $padding-right: 15
          )
        }
        a {
          color: #00bcda;
          text-decoration: underline;
          @include vw(
                  $padding-left: 3
          );
        }
      }
    }
  }
}

/*========*/

//slider
.b-slider {
  &__wrap {
    @include vw(
            $margin: 0 auto 70
    );
    @include respond-to(small) {
      margin-bottom: 30px;
    }
    &__item {
      width: 100%;
      img {
        width: 100%;
      }
    }
    &__prev,
    &__next {
      position: absolute;
      top: 45%;
      background: rgba(255, 255, 255, 0.61);
      color: $blue;
      z-index: 9;
      cursor: pointer;
      @include vw(
              $padding: 12 20,
              $font-size: 24,
              $border: 2 solid $blue,
              $border-radius: 6,
              $left: -26
      );
      line-height: 1;
      transition: all .3s;
      display: block;
      &:hover {
        background: rgba(1, 0, 102, 0.41);
        color: #fff;
        border-color: rgba(1, 0, 102, 0.41);
        transition: all .3s;
      }
      @include respond-to(small) {
        padding: 6px 12px;
        font-size: 14px;
        left: -9px;
      }
    }
    &__next {
      left: auto;
      @include vw(
              $right: -26
      );
      @include respond-to(small) {
        right: -9px;
      }
    }
  }
  &__wrap__nav {
    @include vw(
            $margin: 0 auto 70
    );
    .slick-current {
      img {
        @include vw(
                $border: 2 solid $blue
        )
      }
    }
    &__item {
      @include vw(
              $padding: 0 10
      );
      img {
        cursor: pointer;
        @include vw(
                $max-width: 160,
                $max-height: 60
        );
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

/*========*/