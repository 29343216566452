.b-order-form {
  &__technologies {
    text-align: justify;
    width: 100%;
    @include vw($max-width: 1000,
    $margin: 0 auto);

    p {
      @include vw($font-size: 20);

      a {
        color: #00bcda;

        &.b-underline {
          text-decoration: underline;
        }
      }
    }
  }

  &__button {
    @include vw($margin: 50 0 70);
    text-align: center;

    @include respond-to(small) {
      margin: 30px 0;
    }

    a {
      background: #cd3232;
      display: inline-block;
      color: #fff;
      @include vw($font-size: 28,
      $padding: 15 40,
      $border-radius: 6,
      $border: 1 solid #cd3232);

      &:hover,
      &:active,
      &:focus {
        color: #cd3232;
        background: #fff;
      }

      @include respond-to(small) {
      }
    }
  }

  &__img {
    text-align: center;
    @include vw($margin: 0 0 70);

    @include respond-to(small) {
      margin-bottom: 30px;
    }

    img {
      width: 100%;
      @include vw($max-width: 1000);
    }
  }
}

.order-form {
  width: 100%;
  @include vw($max-width: 1000,
  $margin: 0 auto);
  color: $blue;

  &__hr {
    @include vw($height: 4,
    $margin: 10 -30);
    display: block;
    width: 106.5%;
    background: $blue;

    @include respond-to(620) {
      width: 120%;
    }
  }

  &__subtitle {
    @include vw($font-size: 22,
    $padding: 0 0 5,
    $margin: 5 0 5);
    text-decoration: underline;
    display: inline-block;
    font-weight: bold;
    color: $blue;

    @include respond-to(620) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  .label {
    color: $blue;
    line-height: 1;
    @include vw($font-size: 18,
    $letter-spacing: 0,
    $padding-right: 30);
    cursor: pointer;
  }

  .input {

    flex-grow: 1;
    @include vw($border: 1 solid #000,
    $height: 30,
    $border-radius: 0,
    $max-width: 80,
    $padding: 0 5);
    background: $green;
    display: flex;
    align-items: center;

  }

  .radio {
    @include vw($margin: 0);
    position: relative;

    &__field {
      display: flex;
      align-items: center;
      @include vw($margin-bottom: 3);

      @include respond-to(620) {

        margin-bottom: 5px;;
      }

      &-input {
        @include vw($margin: 0 !important);
        display: none;

        &:checked {
          & ~ .radio__field-label {
            &:after {
              content: '';
              display: block;
              position: absolute;
              @include vw($top: 5,
              $left: 5,
              $width: 10,
              $height: 10);
              background: #fff;
            }

          }

        }
      }

      &-label {
        cursor: pointer;
        position: relative;
        line-height: 1.2;
        min-height: 0;
        @include vw($font-size: 18,
        $padding-left: 25);

        @include respond-to(620) {
          font-size: 16px;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          background: $green;
          @include vw($top: 0,
          $left: 0,
          $width: 20,
          $height: 20,
          $border: 1 solid $blue);
        }

      }

      &-vert {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        &-input {
          &:checked {
            & ~ .radio__field-vert-label {
              &:after {
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                @include vw($top: auto,
                $bottom: 7,
                $width: 20,
                $height: 15);
                background: #fff;
              }

            }

          }
        }

        &-label {
          flex-basis: 100%;
          flex-shrink: 0;
          text-align: center;
          font-weight: bold;
          @include vw($font-size: 18,
          $padding-left: 0,
          $padding-bottom: 32);

          &:before {
            content: '';
            display: block;
            position: absolute;
            background: $green;
            bottom: 0%;
            left: 50%;
            transform: translateX(-50%);
            @include vw($width: 40,
            $height: 30,
            $border: 1 solid $blue,
            $top: auto);
          }
        }
      }
    }
  }

  .checkbox {
    @include vw($margin: 0);
    position: relative;

    &__field {
      display: flex;
      align-items: center;
      @include vw($margin-bottom: 3);

      &-input {
        @include vw($margin: 0 !important);
        display: none;

        &:checked {
          & ~ .checkbox__field-label {
            &:after {
              content: '';
              display: block;
              position: absolute;
              @include vw($top: 5,
              $left: 5,
              $width: 10,
              $height: 10);
              background: #fff;
            }

          }

        }
      }

      &-label {
        cursor: pointer;
        position: relative;
        line-height: 1.2;
        min-height: 0;
        color: $blue;
        @include vw($font-size: 18,
        $padding-left: 25);

        @include respond-to(620) {
          font-size: 16px;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          background: $green;
          @include vw($top: 0,
          $left: 0,
          $width: 20,
          $height: 20,
          $border: 1 solid $blue);
        }

      }
    }

  }

  &__header {
    display: flex;
    justify-content: space-between;
    @include vw($margin-bottom: -48);

    @include respond-to(620) {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  &__title {
    font-weight: bold;
    @include vw($letter-spacing: 2.1,
    $font-size: 42,
    $border: 0,
    $padding: 0);
  }

  &__logo {
    @include vw($flex-basis: 320);
    flex-grow: 0;
    flex-shrink: 0;

    img {
      width: 100%;
    }
  }

  &__section {
    @include vw($margin-bottom: 40);

    &-inner {
      @include vw($padding: 20 30,
      $border: 1 solid #000);
      overflow: hidden;
    }

    &-top {
      @include vw($font-size: 28,
      $margin-bottom: 8);
      color: $blue;
    }
  }

  &__customer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-title {
      @include vw($font-size: 36,
      $border: 0,
      $padding: 0,
      $margin: 0 auto 25,
      $letter-spacing: 2);
      flex-basis: 100%;
      font-weight: bold;
      color: $blue;
      text-align: center;
      display: block;
    }

    &-left {
      flex-basis: 61%;
      flex-grow: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include respond-to(620) {
        flex-basis: 100%;
      }

      .order-form__customer-label {
        @include respond-to(small) {
          flex-basis: 150px;
          font-size: 16px;
        }
      }
    }

    &-right {
      flex-basis: 35%;
      flex-grow: 0;

      @include respond-to(620) {
        flex-basis: 100%;
      }

      .order-form__customer-label {
        flex-basis: 100px;
        padding-right: 5px;

        @include respond-to(small) {
          flex-basis: 150px;
          font-size: 16px;
        }
      }
    }

    &-feild {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-basis: 100%;
      @include vw($margin-bottom: 20);

      &--w70 {
        flex-basis: 48%;
        flex-shrink: 0;

        @include respond-to(620) {
          flex-basis: 100%;
        }

        input {
          @include vw($max-width: 120);

          @include respond-to(620) {
            max-width: 100%;
          }
        }
      }

      &--w30 {
        flex-basis: 30%;
        flex-shrink: 0;

        @include respond-to(620) {
          flex-basis: 100%;
        }

        input {
          @include vw($max-width: 120);

          @include respond-to(620) {
            max-width: 100%;
          }
        }
      }
    }

    &-label {
      color: $blue;
      line-height: 1;
      @include vw($font-size: 30,
      $letter-spacing: 0,
      $padding-right: 30);
    }

    &-input {
      flex-grow: 1;
      @include vw($border: 1 solid #000,
      $height: 38,
      $padding: 10);
      background: $green;
    }

    &-footer {
      color: $blue;
      flex-basis: 53%;

      @include respond-to(620) {
        flex-basis: 100%;
      }
    }
  }

  &__lens {
    &-title {
      @include vw($font-size: 36,
      $border: 0,
      $padding: 0,
      $margin: 0 auto 25,
      $letter-spacing: 2);
      flex-basis: 100%;
      font-weight: bold;
      color: $blue;
      text-align: center;
      display: block;
    }
  }

  &__model {
    &-title {
      @include vw($font-size: 26,
      $margin-bottom: 10);
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @include respond-to(620) {
        flex-wrap: wrap;
      }
    }

    &-col {
      @include vw($margin-right: 18);

      @include respond-to(620) {
        margin-bottom: 20px !important;
      }

      &:last-child {
        @include vw($margin-right: 0);
      }
    }

    &-select {
      flex-basis: 37%;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      @include respond-to(620) {
        flex-basis: 100%;
        margin-bottom: 20px;
      }

      &--label {
        color: $blue;
        line-height: 1;
        @include vw($font-size: 30,
        $letter-spacing: 0,
        $padding-right: 30);
      }

      &--wrap {
        flex-grow: 1;
        @include vw($border: 1 solid #000,
        $height: 38,
        $border-radius: 0);
        background: $green;
        display: flex;
        align-items: center;

      }

      &--input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
      }

    }

    &-radio {
      flex-basis: 18.5%;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100%;
        margin-bottom: 20px;
        font-size: 16px;
      }
    }

    &-checkbox {
      flex-basis: 34%;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100%;
        margin-bottom: 20px;
      }
    }

    &-img {
      flex-grow: 0;
      flex-basis: 10.5%;
      @include vw($margin-top: -40);

      @include respond-to(620) {
        margin-top: 0;
        flex-basis: 100%;
        text-align: center;
        display: none;
      }
    }
  }

  &__color {
    display: flex;
    flex-wrap: wrap;

    &-left {
      flex-basis: 10%;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100%;
        display: flex;

        p {
          &:first-child {
            @include respond-to(620) {

              flex-basis: 100px;
              text-align: left;
              font-size: 18px;
            }

          }

          &:last-child {
            @include respond-to(620) {

              text-align: center;
              font-size: 18px;
              flex-grow: 1;
            }

          }
        }
      }
    }

    &-right {
      flex-basis: 90%;
      flex-shrink: 0;

      @include respond-to(620) {
        display: flex;
        flex-basis: 100%;
      }

      p {
        @include vw($font-size: 18);
        color: $blue;
      }
    }

    &-items {
      display: flex;

      @include respond-to(620) {
        flex-wrap: wrap;
        width: 100%;
      }
    }

    &-field {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 7.5%;
      text-align: center;
      justify-content: center;
      align-items: flex-end;

      @include respond-to(620) {
        flex-basis: 100%;
        margin-bottom: 10px;
      }

      label {
        font-weight: bold;
        order: 0;

        @include respond-to(620) {
          flex-basis: 90px;
          text-align: left;
          font-size: 18px;
        }
      }

      .input {
        order: 1;
        @include vw($max-width: 40,
        $margin: 0 auto);

        @include respond-to(620) {
          max-width: 100%;
          flex-grow: 1;
          height: 28px;
        }
      }
    }

    &-img {
      flex-basis: 100%;
      padding-left: 10%;

      @include respond-to(620) {
        padding: 0;
      }
    }

    &-footer {
      flex-basis: 100%;
      padding-left: 10%;

      @include respond-to(620) {
        padding: 0;
      }
    }
  }

  &__full {
    display: flex;

    @include respond-to(620) {
      flex-wrap: wrap;
    }

    &-left {
      flex-basis: 80%;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100%;
      }
    }

    &-right {
      flex-basis: 20%;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100%;
        text-align: center;
      }
    }

    &-row {
      display: flex;
      flex-wrap: wrap;
    }

    &-items {
      flex-basis: 100%;
      display: flex;
      align-items: flex-start;

      @include respond-to(620) {
        flex-wrap: wrap;
      }
    }

    &-col {
      @include vw($margin-right: 18);

      &:last-child {
        @include vw($margin-right: 0);
      }
    }

    &-qwt {
      flex-basis: 26%;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      @include respond-to(620) {
        flex-basis: 100%;
        margin: 0 0px 20px 0px;
      }

      &--label {
        color: $blue;
        line-height: 1;
        @include vw($font-size: 18,
        $letter-spacing: 0,
        $padding-right: 30);

        @include respond-to(620) {
          font-size: 18px;
          flex-basis: 100px;
        }
      }

      &--input {
        flex-grow: 1;
        @include vw($border: 1 solid #000,
        $height: 30,
        $border-radius: 0,
        $max-width: 80,
        $padding: 0 5);
        background: $green;
        display: flex;
        align-items: center;

        @include respond-to(620) {
          max-width: 100%;
          height: 28px;
        }

      }

    }

    &-radio {
      flex-basis: 23%;
      flex-shrink: 0;
      flex-grow: 0;

      @include respond-to(620) {
        flex-basis: 100%;
        margin-bottom: 20px !important;
      }
    }

    &-checkbox {
      flex-basis: 46%;

      @include respond-to(620) {
        flex-basis: 100%;
        margin-bottom: 20px;
        font-size: 18px;
      }
    }

    &-img {
      flex-basis: 100%;
      @include vw($margin: 7 0);

      @include respond-to(620) {
        display: none;

      }
    }

    &-footer {
      flex-basis: 100%;

      @include respond-to(620) {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  }

  &__position {
    @include vw($border: 0);

    &-header {
      width: 60%;
      color: $blue;
      @include vw($margin-bottom: 10);

      @include respond-to(620) {
        width: 100%;
        font-size: 16px;
        margin-bottom: 20px;
      }
    }

    &-fonts {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @include vw($margin-bottom: 40);

      @include respond-to(small) {
        flex-wrap: wrap;
      }
    }

    &-field {
      @include respond-to(620) {
        flex-basis: 100%;
        margin-bottom: 10px !important;
        display: flex;
        align-items: center;
      }

      .radio__field-label {
        @include vw($font-size: 20);

        @include respond-to(620) {
          font-size: 18px;
          line-height: 0.9;
        }
      }

      &:first-child {
        font-family: "arial-bold";
      }

      &:nth-child(2) {
        font-family: "times-new-roman";
      }

      &:nth-child(3) {
        font-family: "futura";
      }

      &:nth-child(5) {
        font-family: "futura";
      }

      &:nth-child(4) {
        font-family: "Brush";
      }

      &:nth-child(6) {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        flex-basis: 15%;
        @include vw($flex-basis: 130);

        @include respond-to(620) {
          flex-basis: 100%;
          display: flex;
          flex-wrap: nowrap;
        }

        .input {
          flex-basis: 100%;
          order: 1;
          @include vw($width: 130,
          $max-width: 130);
          font-weight: normal;

          @include respond-to(620) {
            width: auto;
            max-width: 100%;
            height: 28px;

          }

        }

        .label {
          font-weight: normal;
          flex-basis: 100%;
          order: 0;
          @include vw($padding: 0);

          @include respond-to(620) {
            font-size: 18px;
            text-align: left;
            flex-basis: 140px;
          }
        }
      }
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
      @include vw($margin-bottom: 60);

      @include respond-to(620) {
        flex-wrap: wrap;
        margin: 0;
      }
    }

    &-group {
      flex-basis: 48%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @include respond-to(620) {
        flex-basis: 100%;
        flex-wrap: wrap;
      }

      &-left {
        @include vw($border: 1 solid #000,
        $padding: 18,
        $width: 190,
        $height: 220);
        position: relative;

        @include respond-to(620) {
          margin: auto auto 35px;
        }

        .radio__field {
          position: absolute;
          @include vw($width: 25,
          $height: 25,
          $margin: 0);

          .radio__field-input {
            &:checked ~ .radio__field-label:after {
              @include vw($width: 11,
              $height: 11,
              $top: 7,
              $left: 7);
            }
          }

          &-label {
            @include vw($width: 25,
            $height: 25);

            &:before {
              @include vw($width: 25,
              $height: 25);
            }
          }
          span {
            color: #333;
            position: absolute;
            font-family: "arial-bold";
            @include vw(
                    $font-size: 12,
                    $width: 89
            );
            display: block;
            text-align: center;
          }

          &:nth-child(1) {
            @include vw($left: 20,
            $top: 20);
            span {
              @include vw(
                      $left: -30,
                      $top: -57
              );
            }
          }

          &:nth-child(2) {
            @include vw($right: 20,
            $top: 20);
            span {
              @include vw(
                      $right: -30,
                      $top: -57
              );
            }
          }

          &:nth-child(3) {
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
            span {
              @include vw(
                      $right: -33,
                      $top: -38
              );
            }
          }

          &:nth-child(4) {
            @include vw($left: 20,
            $bottom: 20);
            span {
              @include vw(
                      $left: -28,
                      $top: -35
              );
            }
          }

          &:nth-child(5) {
            @include vw($right: 20,
            $bottom: 20);
            span {
              @include vw(
                      $right: -26,
                      $top: -35
              );
            }
          }
        }
      }

      &-right {
        position: relative;
        @include vw($width: 245,
        $height: 185,
        $border: 1 solid #000,
        $padding: 18);

        @include respond-to(620) {
          margin: auto;
        }

        .radio__field {
          position: absolute;
          @include vw($width: 25,
          $height: 25,
          $margin: 0);

          .radio__field-input {
            &:checked ~ .radio__field-label:after {
              @include vw($width: 11,
              $height: 11,
              $top: 7,
              $left: 7);
            }
          }

          &-label {
            @include vw($width: 25,
            $height: 25);

            &:before {
              @include vw($width: 25,
              $height: 25);
            }
          }
          span {
            color: #333;
            position: absolute;
            font-family: "arial-bold";
            @include vw(
                    $font-size: 12,
                    $width: 89
            );
            display: block;
            text-align: center;
          }

          &:nth-child(1) {
            @include vw($left: 20,
            $top: 20);
            span {
              @include vw(
                      $left: -30,
                      $top: -57
              );
            }
          }

          &:nth-child(2) {
            @include vw($right: 20,
            $top: 20);
            span {
              @include vw(
                      $right: -30,
                      $top: -57
              );
            }
          }

          &:nth-child(3) {
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
            span {
              @include vw(
                      $right: -33,
                      $top: -38
              );
            }
          }

          &:nth-child(4) {
            @include vw($left: 20,
            $bottom: 20);
            span {
              @include vw(
                      $left: -28,
                      $top: -35
              );
            }
          }

          &:nth-child(5) {
            @include vw($right: 20,
            $bottom: 20,
            $width: auto);
          }
        }
      }
    }

    &-area {
      flex-basis: 44%;
      @include vw($margin-bottom: -60);

      @include respond-to(620) {
        margin: 20px auto 0;
        text-align: center;
      }

      label {
        display: block;
        text-align: right;
        color: $blue;
        @include vw($margin-bottom: 7,
        $font-size: 24);
      }

      textarea {
        display: block;
        width: 100%;
        resize: none;
        @include vw(
                $height: 180,
                $border: 1 solid #000,
                $padding: 7
        );
      }

      &-footer {
        color: $blue;

        a {
          color: $blue;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__spray {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-left {
      flex-basis: 74%;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100%;
      }
    }

    &-right {
      flex-basis: 25%;
      flex-shrink: 0;
      flex-grow: 1;

      @include respond-to(620) {
        flex-basis: 100%;
        text-align: center;
        margin-top: 20px;
      }

      img {
        @include respond-to(620) {
          width: 300px;
        }
      }
    }

    &-items {
      @include vw($margin-bottom: 20);
      display: flex;

      @include respond-to(620) {
        flex-basis: 100%;
        flex-wrap: wrap;
      }
    }

    &-qwt {
      @include vw($margin-right: 40);
    }

    &-info {
      display: flex;
      justify-content: space-around;

      .order-form__spray-col {
        text-align: center;
        @include vw($padding: 0 10);
      }

      header {
        text-decoration: underline;
        @include vw($margin-bottom: 7);

        @include respond-to(small) {
          font-size: 18px;

        }
      }

      ul {
        color: red;

        @include respond-to(small) {
          font-size: 18px;

        }
      }
    }

    hr {
    }

    &-qwt {
      flex-basis: 26%;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      @include respond-to(620) {
        flex-basis: 100%;
        margin: 0 auto 20px;;
      }

      &--label {
        color: $blue;
        line-height: 1;
        @include vw($font-size: 18,
        $letter-spacing: 0,
        $padding-right: 30);

        @include respond-to(620) {
          font-size: 18px;
          flex-basis: 100px;
        }
      }

      &--input {
        flex-grow: 1;
        @include vw($border: 1 solid #000,
        $height: 30,
        $border-radius: 0,
        $max-width: 80,
        $padding: 0 5);
        background: $green;
        display: flex;
        align-items: center;

        @include respond-to(620) {
          flex-grow: 1;
          max-width: 100%;
          height: 28px;
        }
      }

    }

    &-radio {
      flex-basis: 23%;
      flex-shrink: 0;
      flex-grow: 0;

      @include respond-to(620) {
        flex-basis: 100%;
      }
    }
  }

  &__kits {
    display: flex;
    justify-content: space-between;

    @include respond-to(620) {
      flex-wrap: wrap;
    }

    &-items {
      display: flex;
      @include vw($margin-bottom: 40);

      @include respond-to(620) {
        flex-wrap: wrap;
      }
    }

    &-left {
      flex-basis: 80%;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100%;
      }
    }

    &-right {
      flex-basis: 20%;
      flex-shrink: 0;
      align-items: flex-end;
      display: flex;

      @include respond-to(620) {
        flex-basis: 100%;
        text-align: center;

      }

    }

    &-img {
      width: 100%;

      img {
        @include respond-to(620) {
          width: 300px;
        }
      }
    }

    &-qwt {
      flex-basis: 27%;
      display: flex;
      align-items: flex-start;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100%;
        margin: 0 auto 20px;;
      }

      &--label {
        color: $blue;
        line-height: 1;
        @include vw($font-size: 18,
        $letter-spacing: 0,
        $padding-right: 30);

        @include respond-to(620) {
          font-size: 18px;
          flex-basis: 100px;
        }
      }

      &--input {
        flex-grow: 1;
        @include vw($border: 1 solid #000,
        $height: 30,
        $border-radius: 0,
        $max-width: 80,
        $padding: 0 5);
        background: $green;
        display: flex;
        align-items: center;

        @include respond-to(620) {
          flex-grow: 1;
          max-width: 100%;
          height: 28px;
        }

      }
    }

    &-radio {
      flex-basis: 25%;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100%;
        margin-bottom: 20px !important;
      }
    }

    &-list {
      header {
        text-decoration: underline;
        color: $blue;
        @include vw($font-size: 20);

        @include respond-to(620) {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }

      ul {
        li {
          list-style: disc;
          list-style-position: inside;

          @include respond-to(620) {
            font-size: 18px;
          }
        }
      }
    }

    &-color {
      display: flex;

      @include respond-to(620) {
        flex-wrap: wrap;
      }

      &-left {
        @include vw($font-size: 16);
        flex-basis: 13%;
        flex-shrink: 0;
        display: flex;
        align-items: flex-end;

        @include respond-to(620) {
          flex-basis: 100%;
          font-size: 20px;
          margin-bottom: 20px;
        }
      }

      &-right {
        flex-shrink: 0;
        flex-grow: 1;

        @include respond-to(620) {
          flex-basis: 100%;
        }
      }

      &-items {
        display: flex;

        @include respond-to(620) {
          flex-wrap: wrap;
        }
      }

      &-field {
        flex-basis: 7.5%;

        @include respond-to(620) {
          flex-basis: 80px;
          margin-bottom: 20px !important;

        }

        .radio__field-label {
          @include vw($font-size: 14 !important);

          @include respond-to(620) {
            font-size: 14px !important;
          }
        }
      }
    }

  }

  &__cases {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .order-form__subtitle {
      //flex-basis: 100%;
      @include vw($margin-bottom: 30);
    }

    &-left {
      flex-basis: 55%;
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;

      @include respond-to(620) {
        flex-basis: 100%;
        flex-wrap: wrap;
      }
    }

    &-col {
      flex-basis: 50%;
      flex-grow: 0;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100%;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include respond-to(620) {
        margin-bottom: 10px;
      }
    }

    &-img {
      flex-basis: 34%;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100px;
      }
    }

    &-qwt {
      @include vw($padding: 0 10);
      display: flex;

      @include respond-to(620) {
        flex-basis: 100%;
        margin: 0 auto 20px;;
      }

      &--label {
        @include vw($padding-right: 7);

        @include respond-to(620) {
          font-size: 18px;
          flex-basis: 100px;
        }
      }

      .input {
        @include vw($max-width: 60);

        @include respond-to(620) {
          flex-grow: 1;
          max-width: 100%;
          height: 28px;
        }

        @include respond-to(380) {
          max-width: 70px;
        }
      }
    }

    &-info {
      flex-basis: 45%;
      flex-shrink: 0;
      display: flex;
      justify-content: space-around;

      @include respond-to(620) {
        flex-basis: 100%;
      }

      &-col {
        flex-basis: 33%;
        text-align: center;

        header {
          @include vw($margin-bottom: 10);
          text-decoration: underline;

          @include respond-to(620) {
            font-size: 18px;
          }
        }

        ul {
          @include vw($padding: 0 10);

          li {
            color: red;

            @include respond-to(small) {
              font-size: 18px;
            }
          }
        }
      }
    }

    &-footer {
      flex-basis: 100%;
      text-align: center;
      @include vw($margin: 20 0 10);

      @include respond-to(620) {
        font-size: 16px;
      }
    }
  }

  &__pouches {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;

    @include respond-to(620) {
      flex-wrap: wrap;
    }

    .order-form__subtitle {
      span {
        display: block;
        text-align: center;
      }
    }

    &-left {
      flex-basis: 38%;
      flex-shrink: 0;

      @include respond-to(620) {
        flex-basis: 100%;
      }
    }

    &-right {
      flex-basis: 28%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include vw($padding: 0 10);

      @include respond-to(620) {
        flex-basis: 100%;
      }
    }

    &-center {
      flex-basis: 34%;
      flex-shrink: 0;

      @include respond-to(small) {
        flex-basis: 100%;
      }
    }

    &-row {
      display: flex;
      @include vw($margin-bottom: 20);

      @include respond-to(620) {
        flex-wrap: wrap;
      }

      .order-form__pouches-col {
        &:first-child {
          flex-basis: 55%;

          @include respond-to(620) {
            flex-basis: 100%;
            margin: 0 auto 20px;;
          }
        }
      }
    }

    &-qwt {
      @include vw($padding: 0 10);
      display: flex;

      @include respond-to(620) {
        flex-basis: 100%;
        margin: 0 auto 20px;;
        padding: 0;
        font-size: 16px;
      }

      &--label {
        @include vw($padding-right: 7);

        @include respond-to(620) {
          font-size: 18px;
          flex-basis: 100px;
        }
      }

      .input {
        @include vw($max-width: 60);

        @include respond-to(620) {
          flex-grow: 1;
          max-width: 100%;
          height: 28px;
        }

      }
    }

    &-inform {
      @include vw($margin-top: 20);
    }

    &-info {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      text-align: center;
      @include vw($margin-bottom: 20);

      &-row {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 20px;
        text-align: center;

        h3 {
          flex-basis: 100%;
          text-align: center;
          @include vw($margin-bottom: 10,
          $font-size: 22);
          color: $blue;
        }
      }

      &-col {
        flex-basis: 33%;
        @include vw($padding: 0 10);

        header {
          text-decoration: underline;
          color: $blue;
        }

        ul {
          li {
            color: red;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;

    p {
      flex-basis: 50%;
      text-align: center;
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include vw($padding: 0 40);

    @include respond-to(small) {
      flex-basis: 100%;
      flex-wrap: wrap;
      padding: 0;
    }

    &-item {
      flex-basis: 40%;
      text-align: center;

      &:last-child {
        @include respond-to(620) {

          flex-basis: 100%;
          order: 0;
          margin-bottom: 20px;
        }
      }

      &:first-child {
        @include respond-to(620) {

          flex-basis: 100%;
          order: 1;
        }
      }

      p {
        @include vw($font-size: 22);
        color: $blue;
      }
    }
  }

  &__btn {
    background: #4a8f4d;
    color: #fff;
    width: 100%;
    @include vw($font-size: 22,
    $border: 1 solid #4a8f4d,
    $padding: 7 15);

    &:hover {
      background: #fff;
      color: #333;
    }

    input[type="file"] {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;

    }

    label {
      display: inline-block;
      cursor: pointer;

    }
  }
}
.order-form__subtitle__span{
  @include vw(
    $margin-left: 20
  );
 @include respond-to(small){
   margin-left: 0;
 }
}

.order-form__cases__radio{
  width: 75%;
  position: relative;
  top: 4px;
  @include respond-to(small){
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}