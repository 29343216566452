//Color
$dark: #000;
$grey: #777;
$blue: #010066;
$lightblue: rgba(0, 148, 183, 0.09);
$green: #84D2E2;
/*
==============================
Fonts
==============================
*/

$ff: "Roboto", sans-serif;
$sing: "SignPainter", sans-serif;
