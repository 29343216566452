.b-faq {
  &__cloth {
    text-align: justify;
    width: 100%;
    @include vw(
            $max-width: 1000,
            $margin: 0 auto 70
    );
    @include respond-to(small){
      margin-bottom: 30px;
    }
    p {
      @include vw(
              $font-size: 20
      );
    }
    h3 {
      @include vw(
              $font-size: 34,
              $margin: 0 0 30
      );
      color: $blue;
    }
  }
  &__wrap {
    @include vw(
            $max-width: 1000,
            $margin: 0 auto 70
    );
    @include respond-to(small){
      margin-bottom: 20px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    h4 {
      @include vw(
              $font-size: 24,
              $margin: 20 0 30
      );
      color: $blue;
    }
    &__item {
      width: 50%;
      @include vw(
              $padding: 10,
              $margin: 0 0 40
      );
      display: flex;
      flex-direction: column;
      align-items: center;
      @include respond-to(small){
        margin-bottom: 15px;
      }
      img {
        @include vw(
                $margin: 0 0 20
        );
        display: block;
      }
      span {
        display: block;
        text-align: center;
        color: $grey;
        @include vw(
                $font-size: 20
        );
      }
    }
  }
}

/*======*/

//questions
.b-questions {
  @include vw(
          $margin: 0 0 100
  );
  ul {
    text-align: justify;
    @include vw(
            $margin: 0 0 70
    );
    @include respond-to(small){
      margin-bottom: 30px;
    }
    li {
      @include vw(
              $font-size: 20,
              $margin: 0 0 30
      );
      p {
        color: $blue;
        @include vw(
                $font-size: 22,
                $margin: 0 0 10
        );
        span {
          color: #cd3232;
        }
      }
    }
  }
  &__bottom{
    p{
      @include vw(
              $font-size: 22,
              $margin: 0
      );
      a{
        color: #00bcda;
        text-decoration: underline;
      }
    }
  }
}

/*======*/