// title
.b-title {
  &__img {
    background-image: url("/images/1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include vw(
            $padding: 200 0,
            $margin: 0 0 70
    );
    &_product{
      background-image: url("/images/product-page/1.jpg");
    }
    &_order-form{
      background-image: url("/images/order-form/1.png");
    }
    &_faq{
      background-image: url("/images/faq/1.png");
    }
    &_contact{
      background-image: url("/images/contact/1.png");
    }
    @include respond-to(small) {
      margin-bottom: 30px;
    }
    @include respond-to(600) {
      padding: 75px 0;
    }
    h1 {
      font-family: 'Fauna One', serif;
      color: $blue;
      text-align: center;
      @include vw(
              $font-size: 45,
              $margin: 0 0 50,
              $max-width: 710
      );
      &.b-product {
        @include vw(
                $max-width: 800
        );
      }
      &.b-contact{
        max-width: 100%;
      }
      @include respond-to(small) {
        font-size: 28px;
        max-width: 100%;
        text-shadow: 1px 1px #fff;
        text-align: center;
      }
    }
    &__link {
      text-align: center;

      a {
        display: inline-block;
        background: rgba(255, 255, 255, 0.89);
        line-height: 1;
        @include vw(
                $padding: 15 60,
                $font-size: 30,
                $border: 1 solid $blue,
                $border-radius: 6
        );
        color: $blue;
        @include respond-to(small){
          padding: 12px 30px;
          width: 100%;
          max-width: 285px;
        }
        &:hover,
        &:active,
        &:focus {
          background: $blue;
          color: #fff;
        }
      }
    }
  }
}

.b-offering {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  @include vw(
          $margin: 0 0 70
  );
  @include respond-to(small) {
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 25%;
    color: #fff;
    @include vw(
            $padding: 40 30
    );
    @include respond-to(small) {
      width: 50%;
    }
    @include respond-to(460) {
      width: 100%;
    }
    &:first-child {
      background: #107cc1;
    }
    &:nth-child(2) {
      background: #1491e1;
    }
    &:nth-child(3) {
      background: #2aa1eb;
    }
    &:last-child {
      background: #4aafef;
    }
    img {
      display: flex;
      width: 100%;
      @include vw(
              $max-width: 120,
              $margin: 0 0 20
      );
      @include respond-to(small) {
        max-width: 100px;
      }
    }
    p {
      @include vw(
              $font-size: 24,
              $margin: 0 0 30
      );
      @include respond-to(small) {
        font-size: 18px;
      }
    }
    span {
      @include respond-to(small) {
        font-size: 14px;
      }
    }
  }
}

/*========*/

// about
.b-about {
  @include vw(
          $margin: 0 0 70
  );
  @include respond-to(small) {
    margin-bottom: 30px;
  }
  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &__img {
      width: 40%;
      @include respond-to(small) {
        display: none;
      }
      img {
        width: 100%;
      }
    }
    &__list {
      width: 60%;
      @include vw(
              $padding-left: 40
      );
      @include respond-to(small) {
        width: 100%;
        padding-left: 0;
      }
      ul {
        li {
          display: flex;
          @include vw(
                  $font-size: 22,
                  $margin: 0 0 20
          );
          @include respond-to(small) {
            font-size: 14px;
          }
          &:before {
            content: '\2714';
            @include vw(
                    $padding-right: 15
            )
          }
        }
      }
    }
  }
}

/*========*/

// products
.b-product {
  @include vw(
          $max-width: 1920,
          $margin: 0 auto 70
  );
  @include respond-to(small) {
    margin-bottom: 30px;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include vw(
            $margin: 0 0 70,
            $border-bottom: 1 solid #00bcda,
            $border-right: 1 solid #00bcda
    );
    @include respond-to(small) {
      margin-bottom: 30px;
    }
    &__item {
      width: 25%;
      text-align: center;
      @include vw(
              $padding: 30 40,
              $border-top: 1 solid #00bcda,
              $border-left: 1 solid #00bcda
      );
      @include respond-to(small) {
        width: 50%;
      }
      img {
        width: 100%;
        @include vw(
                $max-width: 360
        )
      }
    }
  }
  &__button {
    text-align: center;
    @include vw(
            $margin: 0 0 140
    );
    @include respond-to(small) {
      margin-bottom: 70px;
    }
    &_margin{
      @include vw(
        $margin: 0 0 70
      );
    }

    a {
      display: inline-block;
      background: $blue;
      line-height: 1;
      @include vw(
              $padding: 15 60,
              $font-size: 30,
              $border: 1 solid $blue,
              $border-radius: 6
      );
      color: #fff;
      @include respond-to(small){
        padding: 12px 30px;
        width: 100%;
        max-width: 285px;
      }
      &:hover,
      &:active,
      &:focus {
        background: #fff;
        color: $blue;
      }
    }
  }
  &__prices {
    display: flex;
    justify-content: space-between;
    @include vw(
            $margin: 0 0 140
    );
    &_page{
      @include vw(
              $margin: 0 0 100
      );
    }
    @include respond-to(small) {
      margin-bottom: 60px;
      flex-wrap: wrap;
    }
    &__item {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @include vw(
              $margin: 0 10,
              $border: 1 solid $grey,
              $padding: 20 40
      );
      @include respond-to(600) {
        width: 100%;
        margin: 0 0 55px;
        padding: 14px 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      img {
        width: 100%;
        @include vw(
                $max-width: 150,
                $margin: - 80 0 0
        )
      }
      p {
        margin-bottom: 0;
        color: $blue;
        @include vw(
                $font-size: 24
        );
        @include respond-to(small) {
          font-size: 18px;
        }
      }
      span {
        @include vw(
                $font-size: 24
        );
        color: $grey;
        @include respond-to(small) {
          font-size: 18px;
        }
      }
    }
  }
}

/*========*/