.b-index {
  &__thank{
    text-align: center;
    margin: 20px auto;
    background: #440af9;
    padding: 30px 10px;
    width: 100%;
    max-width: 690px;
    p {
      font-size: 30px;
      color: #fff;
      margin-bottom: 30px;
      @include respond-to(small){
        font-size: 16px;
        margin-bottom: 15px;
      }
      font-weight: bold;
      &:last-child{
        margin-bottom: 0;
      }
    }
    a {
      color: #fff;
      text-decoration: none;
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }

    }
  }
}