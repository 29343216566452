.b-contact-form {
  &__form {
    @include vw(
            $max-width: 700,
            $margin: 50 auto 140,
            $border: 2 solid $blue,
            $padding: 40 50
    );
    @include respond-to(small) {
      margin-bottom: 70px;
      padding: 25px 10px;
    }
  }
  &__item {
    @include vw(
            $margin: 0 0 30,
            $font-size: 20
    );
    &:last-child{
      margin-bottom: 0;
    }
    input, textarea {
      @include vw(
              $border: 1 solid $grey,
              $border-radius: 6,
              $font-size: 20,
              $padding: 10
      );
      outline: none;
      width: 100%;
      resize: none;
      color: $grey;
      &::-moz-placeholder {
        color: $grey;
      }
      &::-webkit-input-placeholder {
        color: $grey;
      }
      &:focus::-webkit-input-placeholder {
        color: transparent;
      }
      &:focus::-moz-placeholder {
        color: transparent;
      }
    }
    button {
      display: block;
      @include vw(
              $max-width: 250,
              $padding: 10,
              $border: 2 solid $blue,
              $font-size: 30
      );
      margin: 0 auto;
      color: #fff;
      background: $blue;
      outline: none;
      transition: all .3s;
      width: 100%;
      text-transform: uppercase;
      &:hover {
        transition: all .3s;
        background: #fff;
        color: $blue;
      }
    }

  }
}